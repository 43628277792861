import React from "react"
import { ErrorResponse } from "../../../components/ErrorsVariants"

import LayoutOrder from "../../../components/Layout/LayoutOrder"

export default function ErrorReject() {
  return (
    <div className="error-pages">
      <LayoutOrder>
        <div className="error-pages-wrapper">
          <ErrorResponse variant="reject" />
        </div>
      </LayoutOrder>
    </div>
  )
}
